<template>
	<div class="main-container">
		<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime'></statistics-time>
		<div class="overview" v-loading="overviewLoading">
			<div class="title">商详页流量概况</div>
			<div class="interview">
				<div class="left">
					浏览访问
				</div>
				<div class="right">
					<div class="item">
						<span class="lable">访客数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV1" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<div v-if="CurrentOverview">
							<span class="num">{{CurrentOverview.ProductUV}}</span>
							<div class="compare" v-if="timeType!=0">
								{{currentKeyword}}
								<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ProductUV,PrevOverview.ProductUV)"></svg-icon>
								<span v-if="PrevOverview.ProductUV">{{commentDatafun(CurrentOverview.ProductUV,PrevOverview.ProductUV)}}%</span>
								<span v-if="PrevOverview.ProductUV==0&&CurrentOverview.ProductUV==0">0%</span>
								<span v-if="PrevOverview.ProductUV==0&&CurrentOverview.ProductUV!=0">100%</span>
							</div>
						</div>
					</div>
					<div class="item">
						<span class="lable">浏览量</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV2" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ProductPV}}</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ProductPV,PrevOverview.ProductPV)"></svg-icon>
							<span v-if="PrevOverview.ProductPV">{{commentDatafun(CurrentOverview.ProductPV,PrevOverview.ProductPV)}}%</span>
							<span v-if="PrevOverview.ProductPV==0&&CurrentOverview.ProductPV==0">0%</span>
							<span v-if="PrevOverview.ProductPV==0&&CurrentOverview.ProductPV!=0">100%</span>
						</div>
					</div>
					<div class="item" v-if="timeType!=0">
						<span class="lable">被访问商品数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV3" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.VisitedProductNum}}</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.VisitedProductNum,PrevOverview.VisitedProductNum)"></svg-icon>
							<span v-if="PrevOverview.VisitedProductNum">{{commentDatafun(CurrentOverview.VisitedProductNum,PrevOverview.VisitedProductNum)}}%</span>
							<span v-if="PrevOverview.VisitedProductNum==0&&CurrentOverview.VisitedProductNum==0">0%</span>
							<span v-if="PrevOverview.VisitedProductNum==0&&CurrentOverview.VisitedProductNum!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">曝光量</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV4" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ExposureCount}}</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ExposureCount,PrevOverview.ExposureCount)"></svg-icon>
							<span v-if="PrevOverview.ExposureCount">{{commentDatafun(CurrentOverview.ExposureCount,PrevOverview.ExposureCount)}}%</span>
							<span v-if="PrevOverview.ExposureCount==0&&CurrentOverview.ExposureCount==0">0%</span>
							<span v-if="PrevOverview.ExposureCount==0&&CurrentOverview.ExposureCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">曝光人数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV5" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ExposureMemberCount}}</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ExposureMemberCount,PrevOverview.ExposureMemberCount)"></svg-icon>
							<span v-if="PrevOverview.ExposureMemberCount">{{commentDatafun(CurrentOverview.ExposureMemberCount,PrevOverview.ExposureMemberCount)}}%</span>
							<span v-if="PrevOverview.ExposureMemberCount==0&&CurrentOverview.ExposureMemberCount==0">0%</span>
							<span v-if="PrevOverview.ExposureMemberCount==0&&CurrentOverview.ExposureMemberCount!=0">100%</span>
						</div>
					</div>
				</div>
			</div>
			<div class="interview flow">
				<div class="left">
					流量转化
				</div>
				<div class="right">
					<div class="item">
						<span class="lable">访问-加购转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV6" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ProductUVAndAddToCartMemberCountConversionRate}}%</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ProductUVAndAddToCartMemberCountConversionRate,PrevOverview.ProductUVAndAddToCartMemberCountConversionRate)"></svg-icon>
							<span>{{Math.abs(CurrentOverview.ProductUVAndAddToCartMemberCountConversionRate-PrevOverview.ProductUVAndAddToCartMemberCountConversionRate).toFixed(2)}}%</span>

						</div>
					</div>
					<div class="item">
						<span class="lable">访问-下单转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV7" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ProductUVAndSubmitOrderMemberCountConversionRate}}%</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ProductUVAndSubmitOrderMemberCountConversionRate,PrevOverview.ProductUVAndSubmitOrderMemberCountConversionRate)"></svg-icon>
							<span>{{Math.abs(CurrentOverview.ProductUVAndSubmitOrderMemberCountConversionRate-PrevOverview.ProductUVAndSubmitOrderMemberCountConversionRate).toFixed(2)}}%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">访问-支付转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip.UV8" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ProductUVAndPayOrderMemberCountConversionRate}}%</span>
						<div class="compare" v-if="timeType!=0">
							{{currentKeyword}}
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ProductUVAndPayOrderMemberCountConversionRate,PrevOverview.ProductUVAndPayOrderMemberCountConversionRate)"></svg-icon>
							<span>{{Math.abs(CurrentOverview.ProductUVAndPayOrderMemberCountConversionRate-PrevOverview.ProductUVAndPayOrderMemberCountConversionRate).toFixed(2)}}%</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime2'></statistics-time>
		<div class="structure">
			<div class="check">
				<div class="check-box">
					<div class="check-label">浏览访问：</div>
					<div class="item-box">
						<div style='display: inline-block;' v-for="(item,index) in checksList1" :key='item.index'>
							<div class="item" v-if="(timeType2!=0||item.show)">
								<el-checkbox :label="item.label" v-model="checks" @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkToolTipArray[index]" placement="top-start">
									<i class="el-icon-warning icon"></i>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
				<div class="check-box" v-if="timeType2!=0">
					<div class="check-label">流量转化：</div>
					<div class="item-box">
						<div style='display: inline-block;' v-for="item in checksList2" :key='item.index'>
							<div class="item">
								<el-checkbox :label="item.label" v-model="checks" @change='handleStructureCheck' :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
								<i class="el-icon-warning icon"></i>
							</div>
						</div>
					</div>
				</div>
				<div style='margin-left:68px;color:#666;'>最多只能同时选择5个指标</div>
			</div>
			<el-input placeholder="搜索" style="width:300px;margin:10px 0;" v-model="keyWard" @input="inputFun">
				<i slot="prefix" class="el-input__icon el-icon-search"></i>
			</el-input>


			<div class="table">
				<!-- :default-sort="{prop: `${defaultSortProp}`, order: 'descending'}" -->
				<el-table v-loading="tableLoading" :data="tableData" style="width: 100%" @sort-change="sortChange" :row-key="rowKey">
					<el-table-column :key="1" label="商品" width="300px">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img :src='imgUrl+scope.row.ImgUrl' />
								<div class="right">
									<div class="name">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>

									</div>
									<span>￥{{scope.row.ProductPrice}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :key="2" v-if="checks.includes(1)" prop='PV' sortable label="浏览量"></el-table-column>
					<el-table-column :key="3" v-if="checks.includes(2)" prop="UV" sortable label="访客数">
						<template slot-scope="scope">
							<span>{{scope.row.UV}}</span>
						<!-- 	<span style="margin-left:10px;color:#999;">{{scope.row.UvRate}}</span>
							<span v-if="scope.row.UvRate>0">%</span> -->
						</template>
					</el-table-column>
					<el-table-column :key="4" v-if="checks.includes(3)" prop="ClickCount" sortable label="点击次数"></el-table-column>
					<el-table-column :key="5" v-if="checks.includes(4)" prop="ClickMemberCount" sortable label="点击人数"></el-table-column>
					<el-table-column :key="6" v-if="checks.includes(5)" prop="ClickRate" sortable label="点击率">
						<template slot-scope="scope">
							<span>{{scope.row.ClickRate}}</span>
							<span v-if="scope.row.ClickRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column :key="7" v-if="checks.includes(6)" prop="BounceRate" sortable label="跳失率">
						<template slot-scope="scope">
							<span>{{scope.row.BounceRate}}</span>
							<span v-if="scope.row.BounceRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column :key="8" v-if="checks.includes(7)" prop="StayTimeAvg" sortable label="平均停留时长（秒）"></el-table-column>
					<el-table-column :key="9" v-if="checks.includes(8)" prop="ShareVisitCount" sortable label="分享访问次数"></el-table-column>
					<el-table-column :key="17" v-if="checks.includes(16)" prop="ShareVisitMemberCount" sortable label="分享访问人数"></el-table-column>
					<el-table-column :key="10" v-if="checks.includes(9)" prop="ExposureCount" sortable label="曝光量"></el-table-column>
					<el-table-column :key="11" v-if="checks.includes(10)" prop="ExposureMemberCount" sortable label="曝光人数"></el-table-column>
					<el-table-column :key="12" v-if="checks.includes(11)" prop="GuideSubmitOrderMoney" sortable label="引导下单金额"></el-table-column>
					<el-table-column :key="13" v-if="checks.includes(12)" prop="GuideSubmitOrderMemberCount" sortable label="引导下单人数"></el-table-column>
					<el-table-column :key="14" v-if="checks.includes(13)" prop="GuideSubmitRate" sortable label="引导访问-下单转化率">
						<template slot-scope="scope">
							<span>{{scope.row.GuideSubmitRate}}</span>
							<span v-if="scope.row.GuideSubmitRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column :key="15" v-if="checks.includes(14)" prop="GuidePayOrderMoney" sortable label="引导支付金额"></el-table-column>
					<el-table-column :key="16" v-if="checks.includes(15)" prop="GuidePayOrderMemberCount" sortable label="引导支付人数"></el-table-column>
					<el-table-column :key="18" v-if="checks.includes(17)" prop="GuidePayRate" sortable label="引导访问-支付转化率">
						<template slot-scope="scope">
							<span>{{scope.row.GuidePayRate}}</span>
							<span v-if="scope.row.GuidePayRate>0">%</span>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="margin-top:20px;float:right;" v-if="page.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40]" :page-size="page.size"
				 :total="page.total" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import statisticsTime from './StatisticsTime'
	import config from '@/config/index'
	import {
		dataProductDetailOverview,
		dataProductDetailOverviewIndex
	} from '@/api/goods'

	export default {
		components: {
			statisticsTime
		},
		data() {
			return {
				tableLoading: false,
				overviewLoading: false,
				currentKeyword: '日',
				timeKeywords: ['较前一日', '较前一周', '较前一月', '较前7天', '较前30天', '天', '天'],
				imgUrl: config.IMG_BASE,
				loading: false,
				startDate: '',
				endDate: '',
				timeType: 1,
				startDate2: '',
				endDate2: '',
				timeType2: 1,
				unShowType: [6, 7, 8, 9, 10],
				trendRadioVisit: 0,
				checks: [1, 2, 4, 5, 6],
				checksList1: [{
						label: 1,
						name: '浏览量',
						show: true,
						props: 'Views',
					},
					{
						label: 2,
						name: '访客数',
						show: true,
						props: 'Visitors',
					},
					{
						label: 3,
						name: '点击次数',
						show: true
					},
					{
						label: 4,
						name: '点击人数',
						show: true
					},
					{
						label: 5,
						name: '点击率',
						show: true
					},
					{
						label: 6,
						name: '跳失率',
						show: true
					},
					{
						label: 7,
						name: '平均停留时长（秒）',
						show: false
					},
					{
						label: 8,
						name: '分享访问次数',
						show: true
					},
					{
						label: 16,
						name: '分享访问人数',
						show: true
					},
					{
						label: 9,
						name: '曝光量',
						show: true
					},
					{
						label: 10,
						name: '曝光人数',
						show: true
					}
				],
				checksList2: [{
						label: 11,
						name: '引导下单金额'
					},
					{
						label: 12,
						name: '引导下单人数'
					},
					{
						label: 13,
						name: '引导访问-下单转化率'
					},
					{
						label: 14,
						name: '引导支付金额'
					},
					{
						label: 15,
						name: '引导支付人数'
					},
					{
						label: 17,
						name: '引导访问-支付转化率'
					}
				],
				keyWard: '',
				tableData: [{
					Name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
					Price: '12',
					Visitors: '88',
					VisitorsPercent: '12%'
				}],
				defaultSortProp: 'Views',
				page: {
					total: 0,
					size: 20,
					current: 1
				},
				CurrentOverview: {},
				PrevOverview: {},
				OrderBy: '',
				IsAsc: '',
				checkTooltip:{
					UV1: '统计筛选时间内，访问商品详情页的人数，1人访问多个商品详情页，记1人',
					UV2: '统计筛选时间内，所有商品详情页被访问的次数，1人访问同一个商品或不同商品多次，计多次',
					UV3: '统计筛选时间内，商品详情页浏览量大于0的商品数量',
					UV4: '统计筛选时间内，商品被展示的次数，包括在全部商品列表页、商城首页、猜你喜欢模块等区域',
					UV5: '统计筛选时间内，看到商品被展示的人数，包括在全部商品列表页、商城首页、猜你喜欢模块等区域被展示',
					UV6: '统计筛选时间内，将商品添加至购物车的人数/商品访客数；添加至购物车的人数与商品访客数均去重',
					UV7: '统计筛选时间内，在商城内下单的人数/商品访客数；下单人数与商品访客数均去重',
					UV8: '统计筛选时间内，商城内支付成功的人数/商品访客数；支付成功的人数与商品访客数均去重',
				},
				checkToolTipArray:[
					'统计筛选时间内，商品详情页被访问的次数，1人访问同一个商品多次，计多次',
					'统计筛选时间内，访问商品详情页的人数，1人访问同一个商品的详情页，记1人',
					'统计筛选时间内，用户在商品详情页产生点击行为的次数，一个人在同一个商品的详情页点击多次，计多次',
					'统计筛选时间内，在商品详情页产生点击行为的人数，1人在同一个商品详情页点击多次，计1人',
					'统计筛选时间内，点击人数/访客数；人数去重',
					'统计筛选时间内，1-点击人数/访客数',
					'',
					'统计筛选时间内，通过他人分享的链接或小程序码进入商品详情页访问的浏览量',
					'统计筛选时间内，通过他人分享的链接或小程序码进入商品详情页的访客数',
					'统计筛选时间内，商品被展示的次数，包括在全部商品列表页、商城首页、猜你喜欢模块等区域',
					'统计筛选时间内，看到商品被展示的人数，包括在全部商品列表页、商城首页、猜你喜欢模块等区域被展示',
				]
			}
		},
		mounted() {
			this.getdatas()
			this.getProductInfor()
			// if (this.timeType2 != 0) {
			// 	this.checks = [2, 4, 12, 14, 15]
			// } else {
			// 	this.checks = [1, 2, 4, 5, 6]
			// 	this.defaultSortProp = 'Views'
			// }
		},
		methods: {

			// 获取流量概况
			async getdatas() {
				try {
					this.overviewLoading = true;
					let data = {
						TimeType: this.timeType,
						// StartDate: this.timeType == 0 ? '' : this.startDate,
						// EndDate: this.timeType == 0 ? '' : this.endDate,
						StartDate:this.startDate,
						EndDate:this.endDate
					}
					let result = await dataProductDetailOverview(data)
					this.CurrentOverview = result.Result.CurrentProductDetailFlowOverview
					let obj = {
						AddToCartMemberCount: 0,
						ExposureCount: 0,
						ExposureMemberCount: 0,
						PayOrderMemberCount: 0,
						ProductPV: 0,
						ProductUV: 0,
						ProductUVAndAddToCartMemberCountConversionRate: 0,
						ProductUVAndPayOrderMemberCountConversionRate: 0,
						ProductUVAndSubmitOrderMemberCountConversionRate: 0,
						StatisEndDate: 0,
						StatisStartDate: 0,
						SubmitOrderMemberCount: 0,
						VisitedProductNum: 0
					}
					this.PrevOverview = result.Result.PrevProductDetailFlowOverview ? result.Result.PrevProductDetailFlowOverview :
						obj


				} catch (e) {
					//TODO handle the exception
					// console.log(e)
				} finally {
					this.overviewLoading = false
				}

			},
			gettoday(){
				let date = new Date()
				let year = date.getFullYear()
				let month = date.getMonth()+1>9?(date.getMonth()+1):('0'+(date.getMonth()+1))
				let day = date.getDate()>9?date.getDate():('0'+date.getDate())
				return [year,month,day].join('-')
			},
			// 获取商品信息
			async getProductInfor() {
				try {
					this.tableLoading = true
					let data = {
						TimeType: this.timeType2,
						Time: this.startDate2,
						KeyWord: this.keyWard,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
					if(this.timeType2 ==0){
						data.Time = ''
					}else if(this.timeType2 ==1){
						let poor = (new Date(this.gettoday()).getTime() - new Date(this.startDate2).getTime())/1000/60/60/24
						if(poor < 8){
							data.StartTime  = this.startDate2
							data.EndTime  = this.endDate2
						}
					}
					// if(this.timeType2 ==1 || this.timeType2 ==0){
					// 	data.StartTime  = this.startDate2
					// 	data.EndTime  = this.endDate2
					// }else{
					// 	data.StartTime  = ''
					// 	data.EndTime  = ''
					// }
					let result = await dataProductDetailOverviewIndex(data)
					this.tableData = result.Result.Results
					this.page.total = result.Result.Total

				} catch (e) {
					//TODO handle the exception
					// console.log(e)
				} finally {
					this.tableLoading = false
				}

			},
			// 搜索事件
			inputFun() {
				this.getProductInfor()
			},
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.page.current = 1;
				this.getProductInfor();
			},
			// 公用计算的方法
			commentDatafun(CurrentData, PrevData) {
				return (Math.abs(CurrentData - PrevData) / PrevData * 100).toFixed(2)
			},
			// 公用的箭头方法
			commentArrowsfun(CurrentData, PrevData) {
				let upOrDown = ""
				if (CurrentData != PrevData) {
					if (CurrentData > PrevData) {
						upOrDown = 'gorise'
					} else {
						upOrDown = 'decline'
					}
				} else {
					upOrDown = ''
				}
				return upOrDown
			},
			rowKey(row) {
				return row.Id
			},
			handleTrendRadio(e) {},
			//check
			handleStructureCheck() {
				// console.log('defaultSortProp1', this.defaultSortProp)
				let checksList = this.checksList1.concat(this.checksList2);
				let checks = this.checks.sort((x, y) => {
					return x - y
				})

				checksList.forEach(t => {
					if (checks[0] == t.label) {
						this.defaultSortProp = t.props
						// console.log('defaultSortProp2', this.defaultSortProp)
					}
				})

			},
			handleSizeChange(val) {
				this.page.size = val
				this.getProductInfor()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.getProductInfor()
			},
			getRangeTime(startDate, endDate, timeType) {
				// console.log('startDate', startDate, endDate, timeType)
				this.startDate = startDate
				this.endDate = endDate
				this.timeType = timeType
				this.currentKeyword = this.timeKeywords[timeType - 1]
				this.getdatas()
			},
			getRangeTime2(startDate, endDate, timeType, startLineTime, endLineTime, groupDate, isRefurbish) {
				// console.log('startDate2', startDate, endDate, timeType)
				this.startDate2 = startDate
				this.endDate2 = endDate
				this.timeType2 = timeType
				// if (!isRefurbish) {
				// 	if (timeType != 0) {
				// 		this.checks = [2, 4, 12, 14, 15]
				// 	} else {
				// 		this.checks = [1, 2, 4, 5, 6]
				// 		this.defaultSortProp = 'Views'
				// 	}
				// }
				
				this.page.current = 1
				this.getProductInfor()
			}
		}
	}
</script>
<style lang="less" scoped>
	.main-container {
		.title {
			font-size: 20px;
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			.right {
				float: right;
				margin-right: 5px;
			}
		}

		.icon {
			color: #999;
			margin-left: 5px;
			cursor: pointer;
		}

		.overview {
			padding: 15px;
			background-color: #fff;
			margin-bottom: 10px;

			.interview {
				background-color: rgb(225, 238, 251);
				overflow: hidden;
				display: flex;
				flex-direction: row;

				.left {
					width: 80px;
					background-color: #409EFF;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					font-size: 14px;
				}

				.right {
					height: 100%;
					color: #606266;
					width: calc(100% - 80px);
					align-items: center;

					.item {
						padding: 25px;
						display: inline-block;
						width: 25%;
					}

					.item:nth-child(5n) {
						word-wrap: break-word;
						word-break: normal;
					}

					.lable {
						line-height: 20px;
						font-size: 12px;
					}

					.icon {
						font-size: 14px;
						color: #999;
						margin-left: 5px;
						cursor: pointer;
					}

					.num {
						line-height: 30px;
						font-size: 18px;
					}

					.compare {
						line-height: 20px;
						font-size: 12px;
					}

					.compare-hidden {
						display: none;
					}
				}
			}

			.flow {
				margin-top: 5px;
				background-color: rgb(225, 251, 231);

				.left {
					background-color: rgb(83, 172, 108);
				}
			}
		}

		.structure {
			font-size: 14px;
			padding: 15px;
			background-color: #fff;

			.check {
				background-color: #eee;
				padding: 15px;
				font-size: 14px;

				.check-box {
					display: flex;
					flex-direction: row;
				}

				.check-label {
					width: 70px;
				}

				.item-box {
					width: calc(100% - 70px);
				}

				.item {
					display: inline-block;
					width: 190px;
					margin-bottom: 10px;
				}

				::v-deep .el-checkbox {
					margin-right: 0 !important;
				}
			}

			.remark {
				padding: 15px 0;
				line-height: 25px;
			}

			.table {
				overflow: hidden;

				.dialog-name-content {
					display: flex;
					flex-direction: row;

					img {
						width: 70px;
						height: 70px;
						object-fit: contain;
						margin-right: 10px;
					}

					.name {
						width: 170px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					span {
						color: #E51C23;
						font-size: 12px;
					}
				}
			}
		}
	}
</style>
